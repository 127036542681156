

import httputil from '../api/http.js'
// PC端
//banner
export const bannerList = (params) => {
    return httputil.get("/webapi/shgk/shjj", params)
}
// 首页
// 首页/各地商会  1
export const hyzqGdshTopList = (params) => {
    return httputil.get("/webapi/shfh/topList", params)
}
// 首页/轮播图  1
export const newsTopList = (params) => {
    return httputil.get("/webapi/news/getTopList", params)
}
// 首页/侨务资讯 /今日时政/信息公开/专题报道/本地新闻/通知公告 1
export const indexList1 = (params) => {
    return httputil.get("/webapi/news/getHomeList1/", params)
}
// 首页/会员动态/侨企风采/地方商会/招商资讯/法律法规 1
export const indexList2 = (params) => {
    return httputil.get("/webapi/news/getHomeList2/", params)
}
// 首页/商会刊物 1
export const indexshkw = (params) => {
    return httputil.get("/webapi/shkw/topList", params)
}
// 首页/会员企业 1 ?
export const hyqyList = (params) => {
    return httputil.get("/webapi/hyzq/hyqyTopList", params)
}
// 首页/会员专区 1
export const hyzqList =(params) => {
	return httputil.get("/webapi/hyzq/homeHyList",params)
}
// 首页/动态列表
export const shdtTopList = (params) => {
    return httputil.get("/webapi/shdt/topList", params)
}
// 首页/新闻资讯
export const newsHometypeList = (params) => {
    return httputil.get("/webapi/news/hometypeList", params)
}
// 首页/会员资讯
export const hyzqHyzxTopList = (params) => {
    return httputil.get("/webapi/hyzq/hyzxTopList", params)
}
// 首页/侨商风采
export const hyzqQsfcTopList = (params) => {
    return httputil.get("/webapi/hyzq/qsfcTopList", params)
}
// 首页/今日时政
export const newsJrszTopList = (params) => {
    return httputil.get("/webapi/news/jrszTopList", params)
}
// 首页/政策法规
export const zcfgTopList = (params) => {
    return httputil.get("/webapi/zcfg/topList", params)
}
// 首页/专业委员会
export const newsZywyhtopList = (params) => {
    return httputil.get("/webapi/news/zywyhtopList", params)
}
// 首页/热门专题
export const zthdTopList = (params) => {
    return httputil.get("/webapi/news/rmztTopList", params)
}
// 首页/各地分会
export const shfhTopList = (params) => {
    return httputil.get("/webapi/shfh/topList", params)
}
// 首页->各地分会列表
export const shfhList = (params) => {
    return httputil.get("/webapi/shfh/list", params)
}
// 首页/商会刊物
export const shkwTopList = (params) => {
    return httputil.get("/webapi/shkw/topList", params)
}
//首页/商会刊物-年份
export const shkwyearList = (params) => {
    return httputil.post("/webapi/shkw/getShkwYearList", params)
}
// 首页/会员风采分类
export const hyzqTypeList = (params) => {
    return httputil.get("/webapi/hyzq/hyfcTypeList2", params)
}
// 首页/专题
export const symposiumeList = (params) => {
    return httputil.get("/webapi/symposium/getTopNewsList", params)
}

// 商会概况
// 商会简介
export const shjjList = (params) => {
    return httputil.get("/webapi/shgk/shjj", params)
}
// 商会章程
export const shzcList = (params) => {
    return httputil.get("/webapi/shgk/shzc", params)
}
// 表彰荣誉
export const shbzryList = (params) => {
    return httputil.get(" /webapi/shgk/shbzryList", params)
}
// 表彰荣誉/详情
export const shgkShbzryDetail = (params) => {
    return httputil.get("/webapi/shgk/shbzryDetail", params)
}
// 领导关怀
export const shldghList = (params) => {
    return httputil.get("/webapi/shgk/shldghList", params)
}
// 领导关怀/详情
export const shgkShldghDetail = (params) => {
    return httputil.get("/webapi/shgk/shldghDetail", params)
}


// 商会动态
// 商会列表
export const shdtTypeList = (params) => {
    return httputil.get("/webapi/shdt/typeList", params)
}
// 商会列表/第一个任务/活动资讯
export const shdtList = (params) => {
    return httputil.get("/webapi/shdt/list", params)
}
export const getShdtTypeName = (params) => {
    return httputil.get("/webapi/shdt/getTypeName", params)
}
// 商会动态/详情
export const shdtDetail = (params) => {
    return httputil.get("/webapi/shdt/detail", params)
}


// 新闻资讯
// 新闻列表
export const newsTypeList = (params) => {
    return httputil.get("/webapi/news/typeList", params)
}
// 新闻资讯/第一个任务/侨务资讯
export const newsList = (params) => {
    return httputil.get("/webapi/news/list", params)
}
export const getNewsTypeName = (params) => {
    return httputil.get("/webapi/news/getTypeName", params)
}
// 新闻资讯/详情
export const newsDetail = (params) => {
    return httputil.get("/webapi/news/detail", params)
}

// 新闻资讯/详情
export const weiNewsDetail = (params) => {
    return httputil.get("/weiapi/news/detail", params)
}
// 会员专区
// 侨商撷英
export const hyzqQsfcList = (params) => {
    return httputil.get("/webapi/hyzq/qsfcList", params)
}
// 侨商撷英/详情
export const hyzqQsfcDetail = (params) => {
    return httputil.get("/webapi/hyzq/detail", params)
}
// 会员风采
export const hyzqHyfcTypeList = (params) => {
    return httputil.get("/webapi/hyzq/hyfcTypeList", params)
}
// 会员风采/详情
export const hyzqHyfcDetail = (params) => {
    return httputil.get("/webapi/hyzq/detail", params)
}
// 会员风采/各职位信息
export const hyzqHyfcList = (params) => {
    return httputil.get("/webapi/hyzq/hyfcList", params)
}
// 会员荣誉
export const hyzqHyryList = (params) => {
    return httputil.get("/webapi/hyzq/hyryList", params)
}
// 会员荣誉/详情
export const hyzqHyryDetail = (params) => {
    return httputil.get("/webapi/hyzq/detail", params)
}
// 会员故事
export const hyzqHygsList = (params) => {
    return httputil.get("/webapi/hyzq/hygsList", params)
}
// 会员故事/详情
export const hyzqHygsDetail = (params) => {
    return httputil.get("/webapi/hyzq/detail", params)
}
// 会员企业
export const hyzqHyqyList = (params) => {
    return httputil.get("/webapi/hyzq/hyqyList", params)
}



// 会员资讯
export const hyzqHyzxList = (params) => {
    return httputil.get(" /webapi/hyzq/hyzxList", params)
}
// 会员资讯详情
export const hyzqHyzxDetail = (params) => {
    return httputil.get("/webapi/hyzq/detail", params)
}

//各地商会
export const hyzqGdshList = (params) => {
    return httputil.get("/webapi/hyzq/gdshList", params)
}
//各地商会详情
export const hyzqGdshDetail = (params) => {
    return httputil.get("/webapi/hyzq/detail", params)
}


//政策法规
export const zcfgList = (params) => {
    return httputil.get("/webapi/zcfg/list", params)
}
//政策法规详情
export const zcfgDetail = (params) => {
    return httputil.get(" /webapi/zcfg/detail", params)
}


//商会刊物
export const shkwList = (params) => {
    return httputil.get("/webapi/shkw/list", params)
}
//商会刊物详情
export const shkwDetail = (params) => {
    return httputil.get("/webapi/shkw/detail", params)
}



// 友情链接
export const yqljList = (params) => {
    return httputil.get("/webapi/yqlj/list", params)
}

// 搜索页--资讯
export const searchZX = (params) => {
    return httputil.get("/webapi/search/news", params)
}
// 搜索页--会员
export const searchHY = (params) => {
    return httputil.get("/webapi/search/members", params)
}
// 搜索页--商会
export const searchSH = (params) => {
    return httputil.get("/webapi/search/gdfhs", params)
}
// pc浏览量
export const countNum = (params) => {
	return httputil.get("/webapi/log/addviewnum", params)
}
// 专题数据
export const getSymList = (params) => {
	return httputil.get("/webapi/symposium/indexNews", params)
}


// 移动端
// 手机端浏览量
export const McountNum = (params) => {
	return httputil.get("/weiapi/log/addviewnum", params)
}
// 首页->商会动态
export const MshdtTopList = (params) => {
    return httputil.get("/weiapi/shdt/topList", params)
}
// 首页->新闻资讯
export const MnewsTopList = (params) => {
    return httputil.get("/weiapi/news/topList", params)
}
// 首页->侨商风采
export const MhyzqQsfcTopList = (params) => {
    return httputil.get("/weiapi/hyzq/qsfcTopList", params)
}
// 首页->今日时政
export const MnewsJrszTopList = (params) => {
    return httputil.get("/weiapi/news/jrszTopList", params)
}
// 首页->会员资讯
export const MhyzqHyzxTopList = (params) => {
    return httputil.get("/weiapi/hyzq/hyzxTopList", params)
}
// 首页->会员企业
export const MhyzqHyqyTopList = (params) => {
    return httputil.get("/weiapi/hyzq/hyqyTopList", params)
}
// 首页->会员荣誉
export const MhyzqHyryTopList = (params) => {
    return httputil.get("/weiapi/hyzq/hyryTopList", params)
}
// 首页->商会刊物
export const MshkwTopList = (params) => {
    return httputil.get("/weiapi/shkw/topList", params)
}
// 首页->政策法规
export const MzcfgTopList = (params) => {
    return httputil.get("/weiapi/zcfg/topList", params)
}

// 首页 /侨务资讯/今日时政/本地新闻/会员动态/侨企风采/地方商会
export const mobileIndexHalf = (params) => {
    return httputil.get("/weiapi/news/getHomeList1", params)
}
// 首页 / 会员专区
export const mobileIndexhyzq = (params) =>{
	return httputil.get("/weiapi/hyzq/homeHyList",params)
}
// 获取首页 /  法律法规 /专题报道 /招商资讯 /信息公开 / 通知公告
export const mobileIndexAll = (params) => {
    return httputil.get("/weiapi/news/getHomeList2", params)
}
