import Vue from 'vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue'
import router from './router'
import  * as http from '@/api/index.js'
import vueSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
Vue.prototype.$http = http
Vue.use(ElementUI);
Vue.use(vueSwiper)

Vue.config.productionTip = false
router.afterEach(() => {
    window.scrollTo(0,0)
})
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
