export default {
  toFormData(formModel) {
    var formData = new FormData()
    for (var name in formModel) {
      var value = formModel[name]
      this.setNameValue({ prefix: name, value, formData })
    }
    return formData
  },
  setNameValue({ prefix, value, formData }) {
    if (value === undefined || value == null) { // undefined的
      return
    }

    if (typeof value === 'object') {
      if (value instanceof FileList) {
        var fileList = value
        for (let i = 0; i < fileList.length; i++) {
          formData.append(prefix, fileList[i])
        }
      } else if (value instanceof Array) {
        if (value.length === 0) {
          return
        }
        for (let i = 0; i < value.length; i++) {
          this.setNameValue({ prefix: prefix + '[' + i + ']', value: value[i], formData })
        }
      } else {
        for (var key in value) {
          this.setNameValue({ prefix: prefix + '.' + key, value: value[key], formData })
        }
      }
    } else {
      formData.append(prefix, value)
    }
  }
}
