<template>
    <div id="app">
        <!-- 头部tap -->
        <Header @dynDataRes="dynDataRes" @infDataRes="infDataRes" v-show="$route.meta.isHeader"/>
        <keep-alive>
            <router-view :dybanucData="dybanucData" :infData="infData" ></router-view>
        </keep-alive>
        <footer-b v-show="$route.meta.isHeader"/>
    </div>
</template>
<script>
    import Header from '@/components/header.vue'
    import footerB from '@/components/footer.vue'

    export default {
        name: 'Home',
        components: {
            Header,
            footerB
        },
        data() {
            return {
                show: true,
                dybanucData: [],
                infData: []
            }
        },
        mounted() {
        },
        methods: {
            dynDataRes(i) {
                this.dybanucData = i
            },
            infDataRes(i) {
                this.infData = i
            }
        }
    }
</script>
<style>
    @import './assets/css/base.css';
    @import './assets/css/m_style.css';
    @import './assets/css/roll.css';
    @import './assets/css/media_1600.css';
    @import './assets/css/media_1400.css';

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        /* text-align: center; */
        color: #2c3e50;
        /* padding: 0 7.2vw; */
    }

    #nav {
        padding: 30px;
    }

    #nav a {
        font-weight: bold;
        color: #2c3e50;
    }

    #nav a.router-link-exact-active {
        color: #42b983;
    }

    .box {
        width: 100%;
    }

    /* .active{
        background-color: #004a95;
    } */

    /* .childActive.child{
        background-color: #f3f9ff;
        color: #005bac;
        font-size: 16px;
    } */

    .fl {
        float: left;
    }
	
</style>
