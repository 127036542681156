import axios from 'axios'
import formdata from './formdata'
import qs from 'qs'
var instance = axios.create()
// 响应时间
instance.defaults.timeout = 60000

// 请求拦截器
instance.interceptors.request.use(
  config => config,
  error => {
    return Promise.reject(error);
  });

//  响应拦截器
instance.interceptors.response.use(
  response => {
    return response.data;
  },
  error => {
    return Promise.reject(error)
  });

// 发送请求
var httputil = {
  get(url, params) {
    if (params) {
      return instance.get(url, {
        params, paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })
        }
      })
    } else {
      return instance.get(url)
    }
  },
  post(url, params) {
    if (params) {
      return instance.post(url, params)
    } else {
      return instance.post(url)
    }
  },
  delete(url, params) {
    if (params) {
      return instance.delete(url, {
        params, paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })
        }
      })
    } else {
      return instance.delete(url)
    }
  },
  put(url, params) {
    if (params) {
      return instance.put(url, params)
    } else {
      return instance.put(url)
    }
  },
  postForm(url, formModel) {
    if (window.FormData == null) {
      throw new Error('window.FormData not exist')
    }
    if (formModel == null) {
      return instance.post(url)
    } else {
      return instance.post(url, formdata.toFormData(formModel))
    }
  }
}

export default httputil
