/*
* 自动向左移动js
* wxq
* 2021-03-11
* dom:目标元素
* num:子元素宽度+右边距离
* arr:总数据
*/

import $ from "jquery";

// function toRem($px) {
//   return $px / 30 + 'rem';
// }

let timer = null;

//pc端的
function leftMove(dom, num, arr) {
  let total = (num * arr.length);
  if (dom) {
    dom.css('width', total * 2 + 'px');
    let m = dom.css("margin-left").split('px')[0];
    timer = setInterval(() => {
      m--;
      if (m == -total) {
        m = 0;
      }
      dom.css("margin-left", m + 'px');
    }, 20);
  }
}

//移动端的
function mLeftMove(dom) {
  if (dom) {
    let as = dom.find('a');
    let total = 0;
    for (let i = 0; i < as.length; i++) {
      let w = $(as[i]).css('width');
      let r = $(as[i]).css('marginRight');
      let wp = Math.ceil(w.split('px')[0]);
      let rp = Math.ceil(r.split('px')[0]);
      total += wp + rp;
    }
    dom.css('width', total + 'px');
    let m = dom.css("margin-left").split('px')[0];
    timer = setInterval(() => {
      m--;
      if (m == -total / 2) {
        m = 0;
      }
      dom.css("margin-left", m + 'px');
    }, 30);
  }
}

//暂停左滑
function stopMove() {
  clearInterval(timer);
  timer = null;
}

export {leftMove, mLeftMove, stopMove}
