<template>
	<div class="footer">
		<div class="footerBox">
			<img class="sblogo" src="@/assets/img/sblogo.png">
			<div class="footerInf">
				<p>北京市朝阳区建外街道金和东路20号院世界华商中心6层</p>
				<p>邮编:100020</p>
				<p style="margin-bottom: 20px;">联系电话:010-65220149</p>
				<p style="line-height: 26px;">版权所有:中国侨商联合会 <a style="color: #fff" href="https://beian.miit.gov.cn"
						target="_blank">京ICP备2021037705号-1</a></p>
				<div style="margin:0 auto; padding:2px 0;">
					<a target="_blank"
						href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010502048298"
						style="display:inline-block;text-decoration:none;height:20px;line-height:20px;"><img
							src="@/assets/img/gaba.png" style="float:left;" />
						<p style="float:left;height:20px;line-height:20px;margin: 0px 0px 0px 5px; color:#ffffff;">京公网安备
							11010502048298号</p>
					</a>
				</div>
			</div>
			<img class="gzhewm" src="@/assets/img/gzhewm.jpg">
			<img class="lhhewm" src="@/assets/img/lhhewm.jpg">
		</div>
		<div class="linkBox" v-if="show">
			<p v-for="item,index in linkData" :key="index" @click="golink(item.linkUrl)">{{item.linkName}}</p>
		</div>
	</div>
</template>

<script>
	// @ is an alias to /src
	// import Header from '@/components/header.vue'

	export default {
		name: 'Home',
		components: {

		},
		watch: {
			$route: {
			  handler: function(route) {
				if(route.fullPath == '/index'){
					this.show = false
				}else{
					this.show = true
				}
			  },
			  immediate: true
			}
		},
		data() {
			return {
				linkData: [], //友情链接数据
				page: 1,
				show: true
			}
		},
		created() {
			this.linkGetData()
			// console.log(this.$router);
			// console.log(this.$router.history.current.fullPath);
		},
		methods: {
			// 友情链接数据
			linkGetData() {
				let _this = this
				this.$http.yqljList({
					page: _this.page
				}).then(res => {
					if (res.code == 200) {
						_this.linkData = res.data.records
						// this.play()
						// console.log(this.linkData)
					}
				})
			},
			golink(url) {
				window.open(url, '_blank')
			}
		},
	}
</script>
<style type="text/css">
	@import '~@/assets/css/member.css';
	@import '~@/assets/css/points.css';

	.footer {
		min-width: 1400px;
		box-sizing: border-box;
		margin: 0 auto;
		/* 			height: 420px; */
		background-image: linear-gradient(180deg,
				#239e77 0%,
				#0868a2 100%),
			linear-gradient(#ccddf8,
				#ccddf8);
		background-blend-mode: normal,
			normal;
		overflow: hidden;
		padding-bottom: 47px;
		/* display: flex; */
	}

	.footerBox {
		display: flex;
		height: 240px;
		width: 1400px;
		margin: 0 auto;

	}

	.sblogo {
		display: block;
		width: 181px;
		height: 156px;
		margin: 27px 100px 88px 57px;
	}

	.footerInf {
		margin-top: 38px;
		font-size: 16px;
		color: #ffffff;
		text-align: left;
		line-height: 30px;
		width: 406px;
	}

	.gzhewm {
		width: 136px;
		height: 155px;
		margin: 43px 52px 0 242px;
	}

	.lhhewm {
		margin-top: 43px;
		width: 136px;
		height: 154px;
	}

	.linkBox {
		box-sizing: border-box;
		padding-top: 48px;
		border-top: 1px solid rgba(255, 255, 255, 0.2);
		width: 1400px;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
	}

	.linkBox p {
		cursor: pointer;
		padding: 0 20px 0;
		margin-bottom: 20px;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
		font-size: 16px;
		color: #e5e5e5;
	}
</style>
