import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '../views/index/index.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
    // 首页重定向
    {path: '/', redirect: '/index'},
    // 首页
    {
        path: '/index',
        name: 'index',
        component: index, meta: {isHeader: true,isSearch:true}
    },
    // 首页->各地分会
    {path: '/gdfh', name: 'gdfhList', component: () => import('@/views/gdfh/list.vue'), meta: {isHeader: true,isSearch:true}},
    // 商会概况+详情
    {path: '/shgk', name: 'shgkIndex', component: () => import('@/views/shgk/index.vue'), meta: {isHeader: true,isSearch:true}},
    {
        path: '/shgk/bzry/detail',
        name: 'shgkBzryDetail',
        component: () => import('@/views/shgk/detail/bzry.vue'),
        meta: {isHeader: true,isSearch:true}
    },
    {
        path: '/shgk/ldgh/detail',
        name: 'shgkLdghDetail',
        component: () => import('@/views/shgk/detail/ldgh.vue'),
        meta: {isHeader: true,isSearch:true}
    },
    // 商会动态+详情
    {path: '/shdt/list', name: 'shdtList', component: () => import('../views/shdt/list.vue'), meta: {isHeader: true,isSearch:true}},
    {
        path: '/shdt/detail',
        name: 'shdtDetail',
        component: () => import('../views/shdt//detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
    // 新闻资讯+详情
    {
        path: '/information',
        name: 'information',
        component: () => import('../views/information/list.vue'),
        meta: {isHeader: true,isSearch:true}
    },
    {
        path: '/information/detail',
        name: 'informationDetail',
        component: () => import('../views/information/detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
    // 会员专区+详情
    {path: '/member', name: 'memberIndex', component: () => import('../views/member/index.vue'), meta: {isHeader: true,isSearch:true}},
    {
        path: '/member/detail',
        name: 'memberDetail',
        component: () => import('../views/member/detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
    // 会员资讯+详情
    {
        path: '/memberInfo',
        name: 'memberInfoList',
        component: () => import('../views/memberInfo/list.vue'),
        meta: {isHeader: true,isSearch:true}
    },
    {
        path: '/memberInfo/detail',
        name: 'memberInfoDetail',
        component: () => import('../views/memberInfo/detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
    // 各地商会+详情
    {path: '/gdsh/list', name: 'gdshList', component: () => import('../views/gdsh/list.vue'), meta: {isHeader: true,isSearch:true}},
    {
        path: '/gdsh/detail',
        name: 'gdshDetail',
        component: () => import('../views/gdsh/detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
	// 搜索页
	{  
		path:'/search',
		name:'search',
		component:() => import('../views/search/index.vue'),
		meta: {isHeader: true,isSearch:false}
	},
	// 会员企业
	{  
		path:'/hyqy',
		name:'hyqy',
		component:() => import('../views/hyqy/index.vue'),
		meta: {isHeader: true,isSearch:true}
	},
    // 今日时政列表+详情
    {path: '/jrsz/list', name: 'jrszList', component: () => import('../views/jrsz/list.vue'), meta: {isHeader: true,isSearch:true}},
    {
        path: '/jrsz/detail',
        name: 'jrszDetail',
        component: () => import('../views/jrsz/detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
    // 政策法规+详情
    {path: '/zcfg/list', name: 'zcfgList', component: () => import('../views/zcfg/list.vue'), meta: {isHeader: true,isSearch:true}},
    {
        path: '/zcfg/detail',
        name: 'zcfgDetail',
        component: () => import('../views/zcfg/detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
    // 商会刊物+详情
    {path: '/shkw/list', name: 'shkwList', component: () => import('../views/shkw/list.vue'), meta: {isHeader: true,isSearch:true}},
    {
        path: '/shkw/detail',
        name: 'shkwDetail',
        component: () => import('../views/shkw/detail.vue'),
        meta: {isHeader: true,isSearch:false}
    },
    {
        path: '/link',
        name: 'linkList',
        component: () => import('../views/link/list.vue'), meta: {isHeader: true,isSearch:true}
    },
	{
		path:'/symposium/20th',
		name:'symposium20th',
		component: () => import('../views/symposium/20th/index.vue'), meta: {isHeader: false,isSearch:false}
	},
    // 移动端
    {
        path: '/mobile',
        name: 'mIndex',
        component: resolve => require(['@/mobile/index.vue'], resolve),
    },
    {
        path: '/mobile/app',
        name: 'mobileApp',
        component: resolve => require(['@/mobile/app.vue'], resolve),
        children: [
            {
                path: '/mobile/shdt/list',
                name: 'mobile_shdtList',
                component: resolve => require(['@/mobile/shdt/list.vue'], resolve),
            },
            {
                path: '/mobile/shdt/detail',
                name: 'mobile_shdtDetail',
                component: resolve => require(['@/mobile/shdt/detail.vue'], resolve),
            },
            {
                path: '/mobile/information',
                name: 'mobile_informationList',
                component: resolve => require(['@/mobile/information/list.vue'], resolve),
            },
            {
                path: '/mobile/information/detail',
                name: 'mobile_informationDetail',
                component: resolve => require(['@/mobile/information/detail.vue'], resolve),
            },
            {
                path: '/mobile/member',
                name: 'mobile_memberList',
                component: resolve => require(['@/mobile/member/index.vue'], resolve),
            },
            {
                path: '/mobile/member/detail',
                name: 'mobile_memberDetail',
                component: resolve => require(['@/mobile/member/detail.vue'], resolve),
            },
            {
                path: '/mobile/jrsz/list',
                name: 'mobile_jrszList',
                component: resolve => require(['@/mobile/jrsz/list.vue'], resolve),
            },
            {
                path: '/mobile/jrsz/detail',
                name: 'mobile_jrszDetail',
                component: resolve => require(['@/mobile/jrsz/detail.vue'], resolve),
            },
            {
                path: '/mobile/memberInfo/list',
                name: 'mobile_memberInfoList',
                component: resolve => require(['@/mobile/memberInfo/list.vue'], resolve),
            },
            {
                path: '/mobile/memberInfo/detail',
                name: 'mobile_memberInfoDetail',
                component: resolve => require(['@/mobile/memberInfo/detail.vue'], resolve),
            },
            {
                path: '/mobile/shkw/list',
                name: 'mobile_shkwList',
                component: resolve => require(['@/mobile/shkw/list.vue'], resolve),
            },
            {
                path: '/mobile/shkw/detail',
                name: 'mobile_shkwDetail',
                component: resolve => require(['@/mobile/shkw/detail.vue'], resolve),
            },
            {
                path: '/mobile/zcfg/list',
                name: 'mobile_zcfgList',
                component: resolve => require(['@/mobile/zcfg/list.vue'], resolve),
            },
            {
                path: '/mobile/zcfg/detail',
                name: 'mobile_zcfgDetail',
                component: resolve => require(['@/mobile/zcfg/detail.vue'], resolve),
            },
            {
                path: '/mobile/shgk',
                name: 'mobile_shgk',
                component: resolve => require(['@/mobile/shgk/index.vue'], resolve),
            },
            {
                path: '/mobile/shgk/bzry/detail',
                name: 'mobile_shgk_bzryDetail',
                component: resolve => require(['@/mobile/shgk/detail/bzry.vue'], resolve),
            },
            {
                path: '/mobile/shgk/ldgh/detail',
                name: 'mobile_shgk_ldghDetail',
                component: resolve => require(['@/mobile/shgk/detail/ldgh.vue'], resolve),
            },
            {
                path: '/mobile/gdsh/list',
                name: 'mobile_gdshList',
                component: resolve => require(['@/mobile/gdsh/list.vue'], resolve),
            },
            {
                path: '/mobile/gdsh/detail',
                name: 'mobile_gdshDetail',
                component: resolve => require(['@/mobile/gdsh/detail.vue'], resolve),
            },
            {
                path: '/mobile/link',
                name: 'mobile_linkList',
                component: resolve => require(['@/mobile/link/list.vue'], resolve),
            },
            {
                path: '/mobile/gdfh/list',
                name: 'mobile_gdfhList',
                component: resolve => require(['@/mobile/gdfh/list.vue'], resolve),
            },
            {
                path: '/mobile/hyqy/list',
                name: 'mobile_hyqyList',
                component: resolve => require(['@/mobile/hyqy/list.vue'], resolve),
            }
        ]
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: 'active'
})

export default router
