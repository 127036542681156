<template>
    <div class="box">
        <div class="headerBox">
            <img src="@/assets/img/dbbjC.png">
			<div class="search-content-box">
				<div class="search-centent">
					<div class="search-box" v-show="$route.meta.isSearch">
				 <el-select v-model="value" placeholder="请选择"
				  @change="selectChange"
				  @mouseenter.native="enter"
				   @mouseleave.native="leave"
				 >
				    <el-option
				      v-for="item in options"
				      :key="item.value"
				      :label="item.label"
				      :value="item.value"
					  >
				    </el-option>
				  </el-select>
				<el-input class="search-input"
				 v-model.trim="searchkey"
				 @focus="inputfocus"
				 @blur="inputblur"
				 @keyup.enter.native="goSearch"
				:placeholder=placeholder
				ref="Sinput"
				 >
				  <i slot="suffix" class="el-icon-search header-icon" @click="goSearch"></i>
				  </el-input>
			</div>
				</div>
				
			</div>
			
        </div>
        <div class="boxNav">
            <ul class="box-nav">
                <li v-for="(item,index) in urlRouter" :key="index"
                    class="box-nav-li">
                    <router-link :class="index==activeIndex?'router-link-active':''"
                                 :to="item.typeId?{path:item.url,query:{typeId:item.typeId}}:{path:item.url}">
                        {{item.title}}
                    </router-link>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
	import $ from 'jquery'
    export default {
        name: 'Header',
        data() {
            return {
                activeIndex: 0,
                urlRouter: [
                    {url: '/index', title: '首页'}, //1
                    {url: '/information?typeId=qwzx', title: '侨务资讯', typeId: 'qwzx'},
                    {url: '/information?typeId=jrsz', title: '今日时政', typeId: 'jrsz'},
                    {url: '/information?typeId=bhxw', title: '本会新闻', typeId: 'bhxw'},
                    {url: '/information?typeId=hydt', title: '会员动态', typeId: 'hydt'},
                    {url: '/information?typeId=qqfc', title: '侨企风采', typeId: 'qqfc'},
                    {url: '/information?typeId=dfsh', title: '地方商会', typeId: 'dfsh'},
                    {url: '/member', title: '会员专区', typeId: 'hyfc'},
                    {url: '/information?typeId=flfg', title: '政策法规', typeId: 'flfg'}, //1
                    {url: '/hyqy', title: '会员企业', typeId: 'hyqy'},
                    {url: '/information?typeId=ztbd', title: '专题报道', typeId: 'ztbd'},
                    {url: '/gdfh', title: '各地商会', typeId: ''}, //1
                    {url: '/information?typeId=zszx', title: '招商资讯', typeId: 'zszx'},
                    {url: '/shkw/list', title: '商会刊物', typeId: ''},
                    {url: '/shgk', title: '商会概况', typeId: ''},
                    {url: '/information?typeId=xxgk', title: '信息公开', typeId: 'xxgk'},
                    {url: '/information?typeId=tzgg', title: '通知公告', typeId: 'tzgg'},
                    {url: '/link', title: '友情链接', typeId: ''}, //1
                ],
				searchkey:'',  // 搜索字段的关键字
				options: [{   // 下拉框的选项
				          value: '资讯',
				          label: '资讯'
				        }, {
				          value: '会员',
				          label: '会员'
				        }, {
				          value: '商会',
				          label: '商会'
				        }],
				value: '资讯',  // 下拉框的值
				placeholder:'搜索',
				ishover:false,   // 是否失去焦点
            }
        },
        watch: {
            $route: "classAdd"
        },
        methods: {
            classAdd() {
                const path = this.$route.path
                // console.log(path)
                const typeId = this.$route.query.typeId
                if (typeId == "qwzx") {
                    this.activeIndex = 1
                } else if (typeId == "jrsz") {
                    this.activeIndex = 2
                } else if (typeId == "bhxw") {
                    this.activeIndex = 3
                } else if (typeId == "hydt") {
                    this.activeIndex = 4
                } else if (typeId == "qqfc") {
                    this.activeIndex = 5
                } else if (typeId == "dfsh") {
                    this.activeIndex = 6
                } else if (typeId == "hyqy") {
                    this.activeIndex = 9
                } else if (path == "/member" || path == "/member/detail") {
                    this.activeIndex = 7
                } else if (typeId == "flfg") {
                    this.activeIndex = 8
                } else if (typeId == "ztbd") {
                    this.activeIndex = 10
                } else if (path.indexOf('/gdfh') == 0) {
                    this.activeIndex = 11
                } else if (typeId == "zszx") {
                    this.activeIndex = 12
                } else if (path.indexOf('/shkw') == 0) {
                    this.activeIndex = 13
                } else if (path.indexOf('/shgk') == 0) {
                    this.activeIndex = 14
                } else if (typeId == "xxgk") {
                    this.activeIndex = 15
                } else if (typeId == "tzgg") {
                    this.activeIndex = 16
                } else if (path == '/link') {
                    this.activeIndex = 17
                } else {
                    this.activeIndex = 0
                }
            },
			// 去搜索页
			goSearch(){
				if(this.searchkey.length >0){
					this.$router.push({
					path:'/search',
					query: {
					    key: this.searchkey,
						type:this.value
					}
				})
			this.searchkey = ''
				}
			},
			// 输入框获取焦点
			inputfocus(){
			    
				$(".el-select").css('display','block')
				$(".search-input .el-input__inner").css("border-radius",'0 20px 20px 0')
				$(".search-input .el-input__inner").css('width','221px')
				this.placeholder = '请输入'
			},
		   // 回复初始样式
			hideSelect(){
				$(".el-select").css('display','none')
				$(".search-input .el-input__inner").css("border-radius",'20px')
				$(".search-input .el-input__inner").css('width','100px')
				this.placeholder = '搜索'
			},
		// 输入框失去焦点
		inputblur(){
			// 选择类别或者输入框有文字 不收起来
			if(this.ishover || this.searchkey.length >0){
				// console.log('不执行')
				return false
			}else{
				this.hideSelect()
			}
		},
		// 下拉框选项是否有改变
		selectChange(){
			// 获取焦点
			this.$nextTick(()=>{
				this.$refs.Sinput.focus()
			})
		},
		enter(){  // 鼠标有移入选择框
			this.ishover = true
			
		},
		leave(){ // 鼠标没有移入选择框
			this.ishover = false
			
		}
        }
    }


</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

    h3 {
        margin: 40px 0 0;
    }

    ul {
        list-style-type: none;
        margin: 0 auto;
    }

    li {
        display: inline-block;
        margin: 0 10px;
    }

    a {
        color: #42b983;
    }

    .boxNav {
        /*        min-width: 1400px; */
        margin: 0 auto;
        background-color: #005bac;
    }

    .yanse {
        background-color: #004a95;
    }

    .router-link-active {
        font-weight: bolder;
    }

    .headerBox {
        /*        min-width: 1400px; */
        height: 192px;
        overflow: hidden;
        margin: 0 auto;
        background: url(~@/assets/img/dbbj.png);
        background-size: 100% 100%;
        background-repeat: no-repeat;
		position: relative;
    }

    .box-nav-li {
        text-align: center;
    }

    .headerBox img {
        display: block;
        width: 1400;
        height: 192px;
        margin: 0 auto;
    }

    .box-nav {
        width: 1200px;
        background-color: #005bac;
        line-height: 48px;
        display: flex;
        flex-wrap: wrap;
    }

    .box-nav > li > a {
        color: #ffffff;
        height: 100%;
        line-height: 48px;
        display: block;
        font-size: 18px;
    }

    .box-nav-li {
        position: relative;
        width: 133px;
        margin: 0;
    }

    .box-nav-child {
        position: absolute;
        z-index: 10;
        left: 0;
        width: 160px;
        top: 48px;
        /* padding-top: 1.4vw; */
        background-color: #ffffff;
        box-shadow: 0px 4px 13px 0px rgba(131, 131, 131, 0.14);
    }

    .box-nav-child > a {
        display: block;
    }

    .box-nav-child > a:nth-of-type(1) {
        margin-top: 20px;
    }

    .child {
        background-color: white;
        font-size: 16px;
        color: #000000;
    }

    .child:hover {
        background-color: #f3f9ff;
        color: #005bac;
        font-size: 16px;
    }
	/* 头部搜索框 */
	.search-content-box{
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -600px;
		width: 1200px;
		height: 192px;
	}
	.search-centent{
		position: relative;
		width: 100%;
		height: 100%;
	}
	/* 搜索的最外围盒子 */
	.search-box{
		position: absolute;
		right: 0;
		top: 71px;
		/* background: rgba(0,0,0,0.5); */
		display: flex;
	}
	/* 下拉框的样式 */
	/deep/.el-select .el-input__inner{
		width: 98px;
		height: 40px;
		border: none;
		text-align: center;
		border-radius: 20px 0 0 20px;
		background: rgba(0,0,0,0.5);
		color: #FFFFFF;
	}
	/* 输入框的图标样式 */
	/deep/.el-input__icon{
		line-height: 40px;
	}
	/* 下拉框的li设置为block */
	.el-select-dropdown__item{
		display: block !important;
	}
      /* 输入框的样式 */
	/deep/.search-input .el-input__inner{
		width: 100px;
		height: 40px;
		border-radius: 20px;
		transition: width 1s linear;
		text-align: left;
		background: rgba(0,0,0,0.5);
		color: #FFFFFF;
		border: none;
	}

	/deep/.el-select-dropdown{
		background: rgba(0,0,0,0.5);
		color: #FFFFFF;
	}
	/deep/.popper__arrow::after {
		border-bottom-color: rgba(0,0,0,0.5) !important;
	}
	.el-select{
		display: none;
		position: relative;
	}
	.el-select::after{
		content: "";
		position: absolute;
		width: 1px;
		height: 22px;
		background-color: #ffffff;
		opacity: 0.3;
		right: 0;
		top: 50%;
		margin-top: -11px;
	}
	/deep/.header-icon{
		line-height: 40px;
		width: 30px;
	}
</style>
