<template>
    <div class="indexBox">
        <div class="index">
            <!-- 首页左边板块 -->
            <div class="index-left">
                <!-- 商会动态 -->
                <div class="index-left-shdt">
                    <!-- tab栏切换 -->
                    <div class="shdt-left-tap">
                        <p :class="tapIndex==index?'taActive':''" :key="index" @click="tapActive(index,item.typeId)"
                           v-for="(item,index) in shdtListData">
                            {{item}}
                        </p>
                    </div>
                    <!-- 侨商资讯信息 -->
                    <div class="shdt-box" v-if="tapIndex == 0 && qwzxList.length>0">
                        <div v-for="(item,index) in qwzxList" :key="index">
                            <!-- 侨商资讯第一条数据-->
                            <div class="shdt-first-info" v-if="index==0">
                                <h3 @click="goDatailPage(item.newsId,'qwzx')">
                                    {{item.newsTitle}}
                                </h3>
                                <p @click="goDatailPage(item.newsId,'qwzx')">{{item.subContent}}</p>
                                <span @click="goDatailPage(item.newsId,'qwzx')">详情 ></span>
                            </div>
                            <!-- 侨商资讯剩下的数据 -->
                            <div class="shdt-info-box" v-else>
                                <p
                                        @click="goDatailPage(item.newsId,'qwzx')">
                                    {{item.newsTitle}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- 今日时政 -->
                    <div class="shdt-box" v-if="tapIndex == 1 && jrszList.length>0">
                        <div v-for="(item,index) in jrszList" :key="index">
                            <!-- 侨商资讯第一条数据-->
                            <div class="shdt-first-info" v-if="index==0">
                                <h3 @click="goDatailPage(item.newsId,'jrsz')">
                                    {{item.newsTitle}}
                                </h3>
                                <p @click="goDatailPage(item.newsId,'jrsz')">{{item.subContent}}</p>
                                <span @click="goDatailPage(item.newsId,'jrsz')">详情 ></span>
                            </div>
                            <!-- 侨商资讯剩下的数据 -->
                            <div class="shdt-info-box" v-else>
                                <p
                                        @click="goDatailPage(item.newsId,'jrsz')">
                                    {{item.newsTitle}}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 专题报道  -->
                <div class="index-left-box ztbg-box">
                    <div class="blueTop"></div>
                    <div class="left-box-top">
                        <p class="fl">专题报道</p>
                        <a @click="committeeUrl" class="more">
                            <p>更多 ></p>
                        </a>
                    </div>
                    <div class="left-box-content ztbd">
                       <ul>
						    <li @click="goDatailPage(item.newsId,'ztbd')" v-for="(item,index) in ztbdList"
                                :key="index">
                                <img :src="item.titleImage2" alt="" srcset="">
                            </li> 
					   </ul>
                          
                      
                    </div>
                </div>
                <!-- 各地商会 -->
                <div class="index-left-box">
                    <div class="greenTop"></div>
                    <div class="left-box-top">
                        <p class="fl">各地商会</p>
                        <a @click="gdfhMore" class="fr more">
                            <p>更多 ></p>
                        </a>
                    </div>
                    <div class="left-box-content">
                        <ol>
                            <li @click="affiliateSkip(item.webUrl)" v-for="(item,index) in gdshData"
                                :key="index">
                                <div class="blackQiu"></div>
                                <section>{{item.shfhName}}</section>
                            </li>
                        </ol>
                    </div>
                </div>
                <!-- 招商资讯  -->
                <div class="index-left-box">
                    <div class="blueTop"></div>
                    <div class="left-box-top">
                        <p class="fl">招商资讯</p>
                        <a @click="featuresUrl" class="fr more">
                            <p>更多 ></p>
                        </a>
                    </div>
                    <div class="left-box-content">
                        <ol>
                            <li @click="goDatailPage(item.newsId,'zszx')" v-for="(item,index) in zszxList"
                                :key="index">
                                <div class="blackQiu"></div>
                                <section>{{item.newsTitle}}</section>
                            </li>
                        </ol>
                    </div>
                </div>

                <!-- 商会刊物 -->
                <div class="index-left-box">
                    <div class="greenTop"></div>
                    <div class="left-box-top">
                        <p class="fl">商会刊物</p>
                        <a @click="moreShkwList()" class="fr more">
                            <p>更多 ></p>
                        </a>
                    </div>
                    <div class="left-box-content">
                        <ol>
                            <li @click="goShkwDetail(item.shkwId)" v-for="(item,index) in shkwData"
                                :key="index">
                                <div class="blackQiu"></div>
                                <section>{{item.shkwName}}</section>
                            </li>
                        </ol>
                    </div>
                </div>
				<!-- 会员企业 -->
				<div class="index-left-box">
				    <div class="blueTop"></div>
				    <div class="left-box-top">
				        <p class="fl">会员企业</p>
				        <a @click="moreJrszList()" class="fr more">
				            <p>更多 ></p>
				        </a>
				    </div>
				    <div class="left-box-content">
				        <ol>
				            <li @click="jumpUrl(item.qyWebUrl,'hyqy')" v-for="(item,index) in hyqyList"
				                :key="index">
				                <div class="blackQiu"></div>
				                <section>{{item.qyName}}</section>
				            </li>
				        </ol>
				    </div>
				</div>
            </div>
            <!-- 首页中间板块 -->
            <div class="index-center">
                <!-- 首页banner图 -->
                <div class="index-swiper-box">
                    <el-carousel @change="swiperchange" indicator-position="none" height="421px">
                        <el-carousel-item class="swiper-item" v-for="item in lbtData" :key="item.newsId">
                            <img :src="item.titleImage" @click="goDatailPage(item.newsId,item.typeId)">
                            <div class="swiperImg-button"></div>
                            <p>{{item.newsTitle}}</p>
                        </el-carousel-item>
                    </el-carousel>
                    <div class="swiper-indicator">{{swiperindex}}/{{lbtData.length}}</div>
                </div>
                 <!-- 专题 -->
				 <div class="symposium" @click="goSymposiumFn(false)">
					 <img src="@/assets/img/sym-banner.jpg" alt="">
				 </div>
				 <div class="symposium" @click="goSymposiumFn(true)">
				 	<img src="@/assets/img/sym-banner2.jpg" alt="">
				 </div>
                <!-- 本会新闻  -->
                <div class="index-center-information">
                    <div class="index-information-top">
                        <div class="titleBox">
                            <h1><span>本会</span>新闻</h1>
                            <div class="shdt-left-p titleBoxInf">
                                <p>CUB NEWS</p>
                                <div></div>
                            </div>
                        </div>
                        <div class="viewMore">
                            <p class="fl" @click="infTapSkip">查看更多</p>
                            <img class="fl" src="@/assets/img/gd.png">
                        </div>

                    </div>
                    <div class="informationInf">
                        <div class="informationInfBox" @click="goDatailPage(item.newsId,'bhxw')"
                             v-for="(item,index) in bhxwList"
                             :key="index">
                            <img :src="item.titleImage">
                            <h3>{{item.newsTitle}}</h3>
                            <p>{{item.subContent}}</p>
                        </div>
                    </div>
                </div>
                <!-- 会员动态  -->
                <div class="index-center-information">
                    <div class="index-information-top">
                        <div class="titleBox">
                            <h1><span>会员</span>动态</h1>
                            <div class="shdt-left-p titleBoxInf">
                                <p>MEMBER DYNAMICS</p>
                                <div></div>
                            </div>
                        </div>
                        <div class="viewMore">
                            <p class="fl" @click="memberInfoSkip">查看更多</p>
                            <img class="fl" src="@/assets/img/gd.png">
                        </div>

                    </div>
                    <div class="informationInf">
                        <div class="informationInfBox" @click="goDatailPage(item.newsId,'hydt')"
                             v-for="(item,index) in hydtList" :key="index">
                            <img :src="item.titleImage">
                            <h3>{{item.newsTitle}}</h3>
                            <p>{{item.subContent}}</p>
                        </div>
                    </div>
                </div>
                <!-- 侨企风采 -->
                <div class="index-center-information">
                    <div class="index-information-top">
                        <div class="titleBox">
                            <h1><span>侨企</span>风采</h1>
                            <div class="shdt-left-p titleBoxInf">
                                <p>OVERSEAS CHINESE ENTERPRISE STYLE</p>
                                <div></div>
                            </div>
                        </div>
                        <div class="viewMore">
                            <p class="fl" @click="qqfcMore">查看更多</p>
                            <img class="fl" src="@/assets/img/gd.png">
                        </div>
                    </div>
                    <div class="commerceInf">
                        <div class="commerceInfBox" @click="goDatailPage(item.newsId,'qqfc')"
                             v-for="(item,index) in qqfcList"
                             :key="index">
                            <img :src="item.titleImage">
                            <h3>{{item.newsTitle}}</h3>
                            <!-- <div v-html="item.subContent"></div> -->
                            <p>{{item.subContent}}</p>
                        </div>
                    </div>
                </div>
                <!-- 地方商会  -->
                <div class="index-center-information">
                    <div class="index-information-top">
                        <div class="titleBox">
                            <h1><span>地方</span>商会</h1>
                            <div class="shdt-left-p titleBoxInf">
                                <p>LOCAL CHAMBER OF COMMERCE</p>
                                <div></div>
                            </div>
                        </div>
                        <div class="viewMore">
                            <p class="fl" @click="moreGdshList">查看更多</p>
                            <img class="fl" src="@/assets/img/gd.png">
                        </div>
                    </div>
                    <div class="commerceInf">
                        <div class="commerceInfBox" @click="goDatailPage(item.newsId,'dfsh')"
                             v-for="(item,index) in dfshList"
                             :key="index">
                            <img :src="item.titleImage">
                            <h3>{{item.newsTitle}}</h3>
                            <!-- <div v-html="item.subContent"></div> -->
                            <p>{{item.subContent}}</p>
                        </div>
                    </div>
                </div>

                <!-- 会员专区-->
                <div class="index-center-information qsfcbj">
                    <div class="index-information-top">
                        <div class="titleBox">
                            <h1><span>会员</span>专区</h1>
                            <div class="shdt-left-p titleBoxInf">
                                <p>MEMABER ZONE</p>
                                <div></div>
                            </div>
                        </div>
                        <div class="viewMore">
                            <p class="fl" @click="elegantSkip">查看更多</p>
                            <img class="fl" src="@/assets/img/gd.png">
                        </div>
                    </div>
                    <div class="index-qsfc-tab">
						<div class="btn-left" @click="moveLeftBtn">
							<img src="../../assets/img/left-btn.png" >
						</div>
						<div class="qsfc-tab-content">
							<!-- tab导航栏 -->
							<div class="tab-box">
	                          <div class="tab-type" v-for="(item,index) in taplist" :key="index">
								  <div class="type-list-SortName" v-if="item.typeSortName != ''">【{{item.typeSortName}}】</div>
								  <div class="type-list" v-for="(list,i) in item.list" :key="i" 
								  :class="list.typeId == memberTypeIndex?'active':''"
								  @click="changeMemberType(list.typeId)">
									 {{list.typeName}}
								  </div>
							  </div>
							</div>
							
						</div>
                      <div class="btn-right" @click="moverightBtn">
						  <img src="../../assets/img/right-btn.png" >
					  </div>
                    </div>
                    <div class="mienInf">

                        <div class="swiper-wrapper haoya" @mouseover="pause" @mouseout="play" ref="moveLogo">
                            <div class="swiper-slide" v-for="(item,index) in hyzqDataList" :key="'qsfc1_'+item.fcId">
                                <div class="swiper-slide-box" @click="gohyfcDailPage(item.fcId)">
                                    <img :src="item.fcImage" alt="">
                                    <div class="swiper-slide-boxDiv" :class="index==0?'swiper-one':''">
                                        <h3>{{item.fcName}}</h3>
                                        <p :title="item.remark">{{item.remark}}</p>
                                    </div>
                                </div>
                            </div>
                            <!-- 大于4条数据才显示 -->
                            <div class="index-hyfc-4" v-if="hyzqDataList.length >4">
                                <div class="swiper-slide" v-for="(item,index) in hyzqDataList"
                                     :key="'qsfc2_'+item.fcId">
                                    <div class="swiper-slide-box" @click="gohyfcDailPage(item.fcId)">
                                        <img :src="item.fcImage" alt="">
                                        <div class="swiper-slide-boxDiv" :class="index==0?'swiper-one':''">
                                            <h3>{{item.fcName}}</h3>
                                            <p :title="item.remark">{{item.remark}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 首页右边板块 -->
            <div class="index-right">
                <!-- 商会概况 -->
                <div class="index-right-box index-right-height">
                    <div class="blueTop"></div>
                    <div class="right-box-top">
                        <!-- <img src="@/assets/img/sjx.png" class="fl"> -->
                        <p class="fl">商会概况</p>
                    </div>
                    <div class="right-box-content">
                        <div @click="pointsSkip('shjj')">
                            <div class="blackQiu"></div>
                            <p>商会简介</p>
                        </div>
						<div @click="pointsSkip('hzzc')">
						    <div class="blackQiu"></div>
						    <p>会长致辞</p>
						</div>
						<div @click="pointsSkip('ldgh')">
						    <div class="blackQiu"></div>
						    <p>领导关怀</p>
						</div>
						<div @click="pointsSkip('bzry')">
						    <div class="blackQiu"></div>
						    <p>表彰荣誉</p>
						</div>
						<div @click="pointsSkip('zzjg')">
						    <div class="blackQiu"></div>
						    <p>组织架构</p>
						</div>
                        <div @click="pointsSkip('shzc')">
                            <div class="blackQiu"></div>
                            <p>商会章程</p>
                        </div>
                    </div>
                </div>
                <!-- 信息公开 -->
                <div class="index-right-box">
                    <div class="greenTop"></div>
                    <div class="right-box-top">
                        <p class="fl">信息公开</p>
                        <a class="fr more">
                            <p @click="xxgkMore">更多 ></p>
                        </a>
                    </div>
                    <div class="right-box-content">
                        <div @click="goDatailPage(item.newsId,'xxgk')" v-for="item,index in xxgkList" :key="index">
                            <div class="blackQiu"></div>
                            <p>{{item.newsTitle}}</p>
                        </div>
                    </div>
                </div>
                <!-- 通知公告 -->
                <div class="index-right-box">
                    <div class="blueTop"></div>
                    <div class="right-box-top">
                        <p class="fl">通知公告</p>
                        <a class="fr more">
                            <p @click="tzggMore">更多 ></p>
                        </a>
                    </div>
                    <div class="right-box-content">
                        <div @click="goDatailPage(item.newsId,'tzgg')" v-for="item,index in tzggList" :key="index">
                            <div class="blackQiu"></div>
                            <p>{{item.newsTitle}}</p>
                        </div>
                    </div>
                </div>
				<!-- 法律法规 -->
				<div class="index-right-box">
				    <div class="greenTop"></div>
				    <div class="right-box-top">
				        <p class="fl">政策法规</p>
				        <a class="fr more">
				            <p @click="moreZcfgList">更多 ></p>
				        </a>
				    </div>
				    <div class="right-box-content">
				        <div @click="goDatailPage(item.newsId,'flfg')" v-for="item,index in flfgList" :key="index">
				            <div class="blackQiu"></div>
				            <p>{{item.newsTitle}}</p>
				        </div>
				    </div>
				</div>
                <!-- 联系我们 -->
                <div class="index-right-box lxwm">
                    <div class="blueTop"></div>
                    <div class="right-box-top">
                        <p class="fl">联系我们</p>
                    </div>
                    <div class="myBox">
                        <div class="right-box-contentDiv">
                            <p>中国侨商联合会秘书处</p>
                            <p>地址：北京市朝阳区建外街道金和东路20号院世界华商中心6层</p>
                            <p>邮编：100020</p>
                            <p>邮箱：</p>
                            <p>huashanghui@vip.126.com</p>
                            <p>网址：</p>
                            <p>http://www.qiaoshang.org.cn</p>
                        </div>
                    </div>
                </div>
                <!-- 微信公众号 -->
                <div class="index-right-box">
                    <div class="blueTop"></div>
                    <div class="right-box-top">
                        <p class="fl">微信公众号</p>
                    </div>
                    <div class="right-box-content wxgzh">
                        <img src="/img/gzhewm.e8836740.jpg">
                    </div>
                </div>
            </div>
        </div>

       <div class="index-link-box">
		   <h3>友情链接</h3>
		   <div class="link-box"> 
		   <p v-for="item,index in linkData" :key="index" @click="golink(item.linkUrl)">{{item.linkName}}</p>
		   </div>
	   </div>
    </div>
</template>

<script>
    import $ from 'jquery'

    export default {

        name: 'Home',
        components: {},
        data() {
            return {
                tapIndex: 0, //商会动态索引值
                infIndex: 0, //新闻资讯激活索引
                show: false,
                infId: '', //新闻资讯的类型id
                tapId: '', //商会动态的类型id
                shdtListData: ['侨务资讯', '今日时政'], //侨务资讯数据 1
                qwzxList: [], //侨商资讯列表数据 1
                jrszList: [], //今日时政列表数据 1
                xxgkList: [], // 信息公开列表数据 1
                ztbdList: [], //  专题报道列表数据 1
                bhxwList: [], // 本地新闻列表数据 1
                tzggList: [], // 通知公告列表数据 1
                hydtList: [], //会员动态列表数据 1
                qqfcList: [], // 侨企风采列表数据 1
                dfshList: [], // 地方商会列表数据 1
                zszxList: [], // 招商资讯列表数据 1
                flfgList: [], // 法律法规列表数据 1
                yyqyListL: [], // 会员企业列表数据 1
                gdshData: [], //各地商会数据 1
                shkwData: [], //商会刊物 1
                linkData: [], // 友情链接数据 1
                leftMove: '', // 向左移动的方法
                stopMove: '', // 停止移动的方法
                swiperindex: 0, //banner的索引值 1
                memberTypeIndex: null, // 会员风采id值
                hyfcData: [], //会员风采数据
                hyqyList: [], //会员企业数据
                lbtData: [], // 轮播图数据 1
                hyzqData: [],  // 会员专区数据 1
                hyzqDataList: [], // 会员专区下的列表数据
				countwideth:0,
				taplist:[],  // 会员专区tap列表
				count:1,
            }
        },
        created() {
            if (/Android|webOS| iPhone localStorage| iPad | iPod |BlackBerry|opera mini|opera mobile|appleWebkit.*mobile|mobile/i
                .test(
                    navigator.userAgent)) {
                if (this.$route.path.indexOf('/mobile') < 0) {
                    this.$router.push({
                        path: "/mobile"
                    });
                }
            } else {
                const {
                    leftMove,
                    stopMove
                } = require("@/util/moveing.js");

                this.leftMove = leftMove;
                this.stopMove = stopMove;


                this.gdshGetData() // 各地商会
                this.shkwGetData() //商会刊物
                // this.linkGetData()
                this.lbtList() //1 轮播图数据
                this.indexListHalf() //1 首页一半数据
                this.indexListAll() // 1 首页剩下一半的数据
                this.indexListyyqy() // 1 会员企业数据列表
                this.gethyzqList()  // 1 会员专区数据列表
				this.indexhyzqList() // 1 会员专区tap分类
				this.getcountFn() // pc端点击量
				this.linkGetData()  // 获取友情链接数据
            }
        },
        destroyed() { //监听销毁事件
            this.pause()
        },
        methods: {
            pause() { // 停止向左移动
                this.stopMove();
            },
            play() { //友情链接开始移动
                if (this.hyzqDataList.length >= 4) {
                    this.leftMove($('.haoya'), 191, this.hyzqDataList);
                } else {
                    this.pause();
                    $('.haoya').css("margin-left", '0px');
                }
            },
            addReadLog() { //这个方法不知道干啥的
                const params = {}
                params.readType = "pchome"
                const userAgent = window.navigator.userAgent
                params.userAgent = userAgent;
            },
			// 获取pc浏览量
			getcountFn(){
				this.$http.countNum().then(res => {
					if (res.code == 200) {
					  // console.log(res)
					}
				})
			},
            // 轮播图数据获取 1
            lbtList() {
                this.$http.newsTopList().then(res => {
                    if (res.code == 200) {
                        this.lbtData = res.data;
                        // console.log(this.lbtData);
                    }
                })
            },
            // 商会动态tap切换数据获取
            tapActive(index) {
                this.tapIndex = index

            },
            // 首页/侨务资讯 /今日时政/信息公开/专题报道/本地新闻/通知公告 1
            indexListHalf() {
                this.$http.indexList1().then(res => {
                    if (res.code == 200) {
                        this.qwzxList = res.data.qwzxList
                        this.jrszList = res.data.jrszList
                        this.xxgkList = res.data.xxgkList
                        this.ztbdList = res.data.ztbdList
                        this.bhxwList = res.data.bhxwList
                        this.tzggList = res.data.tzggList
                        // console.log(this.qwzxList)
                    }
                })

            },
            // 首页/会员动态/侨企风采/地方商会/招商资讯/法律法规 1
            indexListAll() {
                this.$http.indexList2().then(res => {
                    if (res.code == 200) {
                        this.hydtList = res.data.hydtList
                        this.qqfcList = res.data.qqfcList
                        this.dfshList = res.data.dfshList
                        this.zszxList = res.data.zszxList
                        this.flfgList = res.data.flfgList
                    }
                })
            },
            // 会员企业数据获取 1
            indexListyyqy() {
                this.$http.hyqyList().then(res => {
                    if (res.code == 200) {
                        this.hyqyList = res.data
                    }
                })
            },
			
            // 各地商会数据获取 1
            gdshGetData() {
                let _this = this
                this.$http.hyzqGdshTopList().then(res => {
                    if (res.code == 200) {
                        _this.gdshData = res.data
                    }
                })
            },


            // 商会刊物数据获取 1
            shkwGetData() {
                let _this = this
                this.$http.shkwTopList().then(res => {
                    if (res.code == 200) {
                        _this.shkwData = res.data
                    }
                })
            },

            // 会员专区数据获取 1
            gethyzqList() {
                this.$http.hyzqList().then(res => {
                    if (res.code == 200) {
                        this.hyzqData = res.data
                        // console.log(this.hyzqData)
                        // this.memberTypeIndex = this.hyzqData[0].typeId
                        this.hyzqDataList = this.hyzqData[0].hyList
                        this.play()
                    }
                })
            },
			// 会员专区tapList
			indexhyzqList() {
			    this.$http.hyzqTypeList().then(res => {
			        if (res.code == 200) {
						this.taplist = res.data
						// console.log(this.taplist)
						 this.memberTypeIndex = this.taplist[0].list[0].typeId
						 // console.log(this.taplist[0].list[0].typeId)
			        }
			    })
			},
			// 点击改变会员专区tab栏的id
			changeMemberType(id){
				// 根据id改变激活状态
				this.memberTypeIndex = id
				this.hyzqData.forEach((item)=>{
					if(item.typeId == id){
						this.hyzqDataList = item.hyList
					}
				})
				this.pause()
				$('.haoya').css("margin-left", '0px');
				this.play()
			},
 
            // 详情跳转  1
            goDatailPage(id, typeId) {
                if (typeId) {
                    this.$router.push({path: '/information/detail', query: {id: id, typeId: typeId}})
                } else {
                    this.$router.push({path: '/information/detail', query: {id: id}})
                }
            },
            // 会员专区详情跳转
            gohyfcDailPage(id) {
                this.$router.push({path: '/member/detail', query: {id: id}})
            },
            // 专题报道跳转
            committeeSkip(id) {
                this.$router.push({
                    name: 'information',
                    query: {
                        id: id
                    }
                })
            },
            // 各地分会信息跳转
            affiliateSkip(url) {
                window.open(url, '_blank');
            },
            // 商会刊物信息跳转
            goShkwDetail(id) {
                this.$router.push({
                    name: 'shkwDetail',
                    query: {
                        id: id
                    }
                })
            },
            // 今日时政信息跳转
            goJrsjDetail(id) {
                this.$router.push({
                    name: 'jrszDetail',
                    query: {
                        id: id
                    }
                })
            },
            // 政策法规信息跳转
            goZcfgDetail(id) {
                this.$router.push({
                    name: 'zcfgList',
                    query: {
                        id: id
                    }
                })
            },
            // 商会概况跳转
            pointsSkip(typeId) {
                this.$router.push({
                    path: '/shgk',
                    query: {
                        typeId: typeId
                    }
                })
            },
            // 侨商风采跳转
            mienSkip(id) {
                this.$router.push({
                    path: '/member/detail',
                    query: {
                        id: id
                    }
                })
            },
            // 专题报道更多的跳转 1
            committeeUrl() {
                this.$router.push({
                    path: '/information?typeId=ztbd',
                    query: {
                        typeId: 'ztbd'
                    }
                })
            },
            // 招商资讯跳转  1
            featuresUrl() {
                this.$router.push({
                    path: '/information?typeId=zszx',
                    query: {
                        typeId: 'zszx'
                    }
                })
            },
            // 商会刊物更多跳转  1
            moreShkwList() {
                this.$router.push({
                    path: '/shkw/list'
                })
            },
            // 商会动态更多跳转
            shdtSkip() {
                let _this = this
                this.$router.push({
                    path: '/shdt/list',
                    query: {
                        typeId: _this.tapId
                    }
                })
            },
            // 本会新闻更多跳转  1
            infTapSkip() {
                // let _this = this
                this.$router.push({
                    path: '/information?typeId=bhxw',
                    query: {
                        typeId: 'bhxw'
                    }
                })
            },
            // 地方商会更多跳转  1
            moreGdshList() {
                this.$router.push({
                    path: '/information?typeId=dfsh'
                })
            },
            // 会员动态更多跳转  1
            memberInfoSkip() {
                this.$router.push({
                    path: '/information?typeId=hydt'
                })
            },
            // 会员专区更多跳转  1
            elegantSkip() {
                this.$router.push({
                    path: '/member',
                    query: {
                        typeId: 'hyfc'
                    }
                })
            },
            // 会员企业更多跳转 1
            moreJrszList() {
                this.$router.push({
                    path: '/hyqy',
                    query: {
                        typeId: 'hyqy'
                    }
                })
            },
            // 信息公开更多跳转
            xxgkMore() {
                this.$router.push({
                    path: '/information?typeId=xxgk'
                })
            },
            // 通知公告更多跳转
            tzggMore() {
                this.$router.push({
                    path: '/information?typeId=tzgg'
                })
            },
            // 政策法规更多跳转  1
            moreZcfgList() {
                this.$router.push({
                    path: '/information?typeId=flfg'
                })
            },
            // 各地分会跳转  1
            gdfhMore() {
                this.$router.push({
                    path: '/gdfh'
                })
            },
            swiperchange(e) { //动态改变swiper的索引值
                // console.log(e)
                this.swiperindex = e + 1
            },
            qqfcMore() { //侨企风采更多跳转 1
                this.$router.push({
                    path: '/information?typeId=qqfc'
                })
            },
            // 会员企业跳转  1
            jumpUrl(url, dataType) {
                if (url && url != "") {
                    if (dataType == 'hyqy') {
                        window.open(url, '_blank');
                    } else {
                        window.location.href = url
                    }
                }
            },
			// 跳转到专业列表
			goSymposiumFn(isExternal){
				if(isExternal){
					window.open('http://www.qiaoshang.org.cn/information/detail?id=1675306974972&typeId=hydt',"_self")
				}else{
					this.$router.push({
					name:'symposium20th'
				    })
				}
			
			},
			// 点击向左移动
			moveLeftBtn(){	
				let qsfcTabContent = $(".qsfc-tab-content").width()
				 // 向左移动三分之一 qsfc-tab-content盒子的距离
				 let moveright = qsfcTabContent / 3
				 // 获取margin-left的距离
				 let moverightcount =  Math.ceil($(".tab-box").css("marginLeft").split('px')[0])
				 // console.log(moverightcount)
				 if(moverightcount<-moveright){
					 $(".tab-box").css("margin-left", moverightcount+moveright + 'px');
					 this.count--
				 }else{
					 $(".tab-box").css("margin-left", 0 + 'px');
					 this.count = 1
				 }
			},
			// 点击向右移动
			moverightBtn(){
				let qsfcTabContent = $(".qsfc-tab-content").width()
			     let tabbox = this.getTabBoxLength()
				  // 向左移动三分之一 qsfc-tab-content盒子的距离
				  let moveleft = qsfcTabContent / 3
				  // 超出盒子tab-box的长度
				  let m = tabbox-qsfcTabContent
				  // tab-box向左移动的margin距离
                  let moveleftcount = moveleft * this.count
				  // console.log(moveleftcount)
				  // 长度有超出tab-box盒子，并且向左移动的距离加上qsfc-tab-content的距离
				  // 小于tab-box的总长度  向左移动三分之一 qsfc-tab-content的距离
				 if(m >0 && moveleftcount + qsfcTabContent < tabbox){
				 	$(".tab-box").css("margin-left", -moveleft * this.count + 'px');
				 	this.count ++	
				 }else{
					 // 向左移动的距离加上qsfc-tab-content的宽度大于tab-box的总长度 
					 // 就移动超出盒子tab-box的长度
					 $(".tab-box").css("margin-left", -m + 'px');
				 }
			},
			 // 盒子tab-box中的总长度
			 getTabBoxLength(){
				 let au =$(".tab-box .tab-type")
				 // 盒子tab-box中的总长度
				 let sum =0
				 for (let i = 0; i < au.length; i++) {
				 	let w = $(au[i]).css('width');
				 	let l = $(au[i]).css('marginLeft');
				 	let wp = Math.ceil(w.split('px')[0]);
				 	let lp = Math.ceil(l.split('px')[0]);
				 	sum += wp + lp;
				 }
				return sum
			 },
			 // 友情链接数据
			 linkGetData() {
			 	let _this = this
			 	this.$http.yqljList({
			 		page: 1
			 	}).then(res => {
			 		if (res.code == 200) {
			 			_this.linkData = res.data.records
			 			// this.play()
			 			// console.log(this.linkData)
			 		}
			 	})
			 },
			 golink(url) {
			 	window.open(url, '_blank')
			 }
        }
    }
</script>
<style scoped>
    @import '~@/assets/css/index.css';

    .swiper-slide {
        margin-right: 11px;
        text-align: center;
        /* font-size: 18px; */
        background: #fff;
        width: 178px !important;
        cursor: pointer;
        height: 178px !important;
    }
	.index-link-box {
		box-sizing: border-box;
		width: 1200px;
		margin: 0 auto;
		margin-bottom: 49px;
	}
	.index-link-box h3{
		font-size: 20px;
		color: #333333;
		margin-bottom: 50px;
	}
	.link-box{
		display: flex;
		flex-wrap: wrap;
	}
	.index-link-box p {
		cursor: pointer;
		padding-right: 42px;
		margin-bottom: 20px;
		border-right: 1px solid rgba(255, 255, 255, 0.2);
		font-size: 16px;
		color: #333333;
		position: relative;
	}
	.index-link-box p::after{
		position: absolute;
		display: block;
		content: '';
		width: 1px;
		height: 19px;
		right: 21px;
		top: 50%;
		transform: translateY(-50%);
		background: #333333;
		opacity: 0.2;
	}
</style>
